























































































































































import { Watch, Component, Vue } from "vue-property-decorator";

import InputComonentVue from "@/components/BasicConponents/InputConponentsVue.vue";
import { Commodity } from "@/common/Views/BeefOrdering/CommodityClassification/CommodityClassification";
import { Column } from "@/common/Views/system/column/column";
import { getGroupCodeForUser319, getForGroupCodelistCity } from "@/api/index";
import { capacityAllocation } from "@/common/Views/BeefOrdering/CapacityAllocation/CapacityAllocation";
import map from "@/components/Map/Map.vue";
@Component({
    components: {
        InputComonentVue,
        "v-map": map,
    },
})
export default class AddCapacityAllocation extends Vue {
    private form: any = {
        shippingLocation: "", //发货地名称
        province: "", //省
        city: "", //市
        area: "", //区
        address: "", //详细地址
        regionCode: "", //区域编码
        coveringCities: "", //所覆盖城市
        transportTimeList: [], //配送时间
        transportTimeListtemp: [], //配送时间
        regionCodetemp: [],
        coveringCitiestemp: [],
    };
    private seDisabled = false;
    private updateType = false;
    private rules: any = {
        shippingLocation: [
            { required: true, message: "请设置发货地名称", trigger: "change" },
        ],
        regionCodetemp: [
            {
                required: true,
                message: "请设置发货地址",
                trigger: "change",
            },
        ],
        coveringCitiestemp: [
            { required: true, message: "请选择覆盖城市", trigger: "change" },
        ],
        transportTimeListtemp: [
            { required: true, message: "请设置配送时间", trigger: "change" },
        ],
    };
    private addTime(type: any) {
        this.form.transportTimeListtemp.push({
            dateType: type,
            startTime: "",
            endTime: "",
            selectedTime: [
                new Date(2024, 8, 9, 0, 0),
                new Date(2024, 8, 9, 23, 59),
            ],
        });
    }
    //时间戳转时间
    private formatTimeArray(timeArray: any) {
        return timeArray.map((date: any) => {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            minutes = minutes < 10 ? "0" + minutes : minutes;
            return `${hours}:${minutes}`;
        });
    }
    //时间转时间戳
    private formatTime(time: any) {
        let date = new Date(time);
        return date.getTime();
    }
    private selectedTime = [
        new Date(2024, 8, 9, 6, 0),
        new Date(2024, 8, 9, 6, 0),
    ];
    private changeTime(index: any) {
        console.log(index, 200);
        console.log(this.selectedTime, 200);
    }
    private treeData = [];
    private propsObj: any = {
        checkStrictly: true,
        lazy: true,
        expandTrigger: "hover",
        value: "id",
        label: "name",
        lazyLoad(node: any, resolve: any) {
            getGroupCodeForUser319(
                node.value ? node.value : 0,
                (window as any).isInternational,
                (res: any) => {
                    try {
                        let nodes = res.data;
                        nodes.forEach((item: any) => {
                            item.leaf = item.level === 3;
                        });
                        resolve(nodes);
                    } catch (e) {}
                }
            );
        },
    };
    private cityList = [];
    private getTree(cb?: Function): void {
        getForGroupCodelistCity((res: any) => {
            console.log(res, 205);
            this.cityList = res.data;
            // data.data.forEach((item: any) => {
            //     if(item.name.includes("市")){
            //         this.cityList.push(item)
            //     }
            // });
        });
    }
    private handleCascader(e: any) {
        console.log(e, 199);
    }
    private addressNumberArray: any = [];
    private addressList: any = [];
    private testValue(val: any) {
        console.log(val, 251);

        let cascaderRef: any = this.$refs.cascaderRef;
        this.form.regionCode = val;
        this.addressList = cascaderRef.getCheckedNodes()[0].pathLabels;
        console.log(cascaderRef.getCheckedNodes()[0].pathLabels, 201);
    }
    //事件
    private SaveData() {
        //保存
        if (this.addressList.length > 0) {
            this.form.province = this.addressList[0];
            this.form.city = this.addressList[1];
            this.form.area = this.addressList[2];
        }
        if (this.form.regionCodetemp.length == 0) {
            this.$message({
                type: "warning",
                message: "请设置发货地址",
            });
            return;
        } else if (this.form.regionCodetemp.length == 1) {
            this.$message({
                type: "warning",
                message: "请选择发货地址城市",
            });
            return;
        } else if (this.form.regionCodetemp.length == 2) {
            this.$message({
                type: "warning",
                message: "请选择发货地址区域",
            });
            return;
        }
        if (this.form.regionCodetemp.length > 0) {
            console.log(this.form.regionCode, 268);
            this.form.regionCode = this.form.regionCodetemp.join(",");
        }
        if (this.form.coveringCitiestemp.length > 0) {
            this.form.coveringCities = this.form.coveringCitiestemp.join(",");
        }
        if (this.form.transportTimeListtemp.length > 0) {
            let flag = true;
            let hasDateType1 = false;
            let hasDateType2 = false;

            this.form.transportTimeListtemp.forEach((item: any) => {
                if (item.selectedTime) {
                    const timeArray = this.formatTimeArray(item.selectedTime);
                    item.startTime = timeArray[0];
                    item.endTime = timeArray[1];

                    // 同时检查 startTime 和 endTime 是否存在
                    if (!item.startTime || !item.endTime) {
                        flag = false;
                    }

                    // 检查是否包含 dateType = 1 和 dateType = 2 的数据
                    if (item.dateType === 1) {
                        hasDateType1 = true;
                    } else if (item.dateType === 2) {
                        hasDateType2 = true;
                    }
                } else {
                    flag = false;
                }
            });

            // 如果任何时间不完整，或者没有同时包含 dateType = 1 和 dateType = 2，则提示错误信息
            if (!flag || !(hasDateType1 && hasDateType2)) {
                this.$message({
                    type: "warning",
                    message:
                        "请选择配送时间",
                });
                return;
            }

            // 通过检查，提交数据
            this.form.transportTimeList = [...this.form.transportTimeListtemp];
            this.form.transportTimeList.forEach((item: any) => {
                delete item.selectedTime;
            });
        }

        if (this.form.shippingLocation == "") {
            this.$message({
                type: "warning",
                message: "请输入发货地名称",
            });
            return;
        } else if (this.form.province == "") {
            this.$message({
                type: "warning",
                message: "请设置发货地址",
            });
            return;
        } else if (this.form.address == "") {
            this.$message({
                type: "warning",
                message: "请输入详细地址",
            });
            return;
        } else if (this.form.coveringCities.length == 0) {
            this.$message({
                type: "warning",
                message: "请选择所覆盖城市",
            });
            return;
        } else if (this.form.transportTimeList.length == 0) {
            this.$message({
                type: "warning",
                message: "请设置配送时间",
            });
            return;
        }
        console.log(this.form, 137);
        this.$refs.form.validate((flag: boolean) => {
            if (flag) {
                this.addtransport(this.form);
            }
        });
    }

    //emit事件
    private SetshippingLocationValue(val: string) {
        //商户名称
        this.form.shippingLocation = val;
    }
    private SetaddressValue(val: string) {
        //详细地址
        this.form.address = val;
    }
    private SetKeyValue(val: any[]) {
        //key
        this.form.sort = val;
    }
    private addtransport(form: any) {
        //新增
        new capacityAllocation().addtransport(form, (res: any) => {
            this.$message({
                type: "success",
                message: "新增发货地成功",
            });
            this.$router.push({
                path: "/CapacityAllocation",
            });
        });
    }
    private ClearFormData() {
        //清楚Form数据
        this.form = {
            shippingLocation: "", //发货地名称
            province: "", //省
            city: "", //市
            area: "", //区
            address: "", //详细地址
            regionCode: "", //区域编码
            coveringCities: "", //所覆盖城市
            transportTimeList: [], //配送时间
            transportTimeListtemp: [], //配送时间
            regionCodetemp: [],
            coveringCitiestemp: [],
        };
    }
    private ClearCheck() {
        //清除校验
        this.$refs.form.clearValidate();
    }
    private listAllOne = [];
    private wu = {
        id: 0,
        categoryName: "无",
    };
    private type = "";
    activated() {
        this.getTree();
        this.ClearFormData();
        this.ClearCheck();
    }
    private showMap: boolean = false;
    private getLngLat(data: string, address: string): void {
        this.showMap = false;
        this.form.location = data;
        console.log(this.form, "form");
    }
    private showMapFun() {
        this.showMap = true;
    }
}

import { BasicsClass } from '@/common/BasicsClass';



export class capacityAllocation extends BasicsClass {
  //获取运力配置列表
  public gettransportList(form: any, page: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/merchant/transport/page', { ...form, ...page }, false, false, cb);
  }
  //获取运力配置详情
  public gettransportInfo(id: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/merchant/transport/getInfo', { id }, false, false, cb);
  }
  //新增运力配置
  public addtransport(form: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/merchant/transport/save', {...form}, false, false, true, cb);
  }
  //修改运力状态
  public updatetransportStatus(form: any, cb: any): void {
    this.BasicGet('/animal/web/ordering/merchant/transport/updateStatus', {...form}, false, false, cb);
  }
  //修改运力配置
  public updatetransport(form: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/merchant/transport/update', {...form}, false, false, true, cb);
  }

  

}

import { Util } from "@/common/Views/comm/util";

declare var BMap: any;

interface Point {
    lng: number;
    lat: number;
}

interface Address {
    addressComponents: {
        streetNumber: string;
        street: string;
        district: string;
        city: string;
        province: string;
    }
}

export class Map {

    private id: string;
    private map: any;

    public lng!: number;
    public lat!: number;

    public address: string = '';

    constructor(id: string) {
        this.id = id;
    }

    public initMap(lng = 116.404, lat = 39.915): any {
        this.map = new BMap.Map('map');
        const point = new BMap.Point(lng, lat);
        this.map.centerAndZoom(point, 15);
        this.map.enableScrollWheelZoom(true);

        return this.map;
    }

    public setIcon(lng: number, lat: number, imgUrl: string, size: number = 32): void {
        const pt = new BMap.Point(lng, lat);
        const myIcon = new BMap.Icon(imgUrl, new BMap.Size(size, size));
        const marker = new BMap.Marker(pt, {icon: myIcon});

        this.map.addOverlay(marker);

    }

    public getPosition(addr: string): void {
        const myGeo = new BMap.Geocoder();
        myGeo.getPoint(addr, (point: Point) => {

            if (!point) {
                Util.showToast('未搜索到当前位置', 'no');

                return;
            }

            this.map.centerAndZoom(point, 16);
            // const myIcon = new BMap.Icon(require('@/assets/location.png'), new BMap.Size(42, 42));
            // this.map.addOverlay(new BMap.Marker(point, {icon: myIcon}));

            this.map.clearOverlays();
            this.setIcon(point.lng, point.lat, require('@/assets/location.png'), 42);

            this.lat = point.lat;
            this.lng = point.lng;

            this.getLocation(this.lng, this.lat);

        }, addr);
    }

    public clickGetPosition(event: string, callback?: Function): void {
        this.map.addEventListener(event, (e: { point: Point }) => {

            this.lat = e.point.lat;
            this.lng = e.point.lng;

            this.map.clearOverlays();

            this.setIcon(e.point.lng, e.point.lat, require('@/assets/location.png'), 42);

            this.getLocation(this.lng, this.lat);

            if (callback) {
                callback(e);
            }
        });
    }

    private getLocation(lng: number, lat: number): void {
        const geoc = new BMap.Geocoder();
        this.address = '';
        geoc.getLocation(new BMap.Point(lng, lat), (rs: Address) => {
            this.address = `${rs.addressComponents.province}${rs.addressComponents.city}${rs.addressComponents.district}${rs.addressComponents.street}${rs.addressComponents.streetNumber}`;
        });

    }
}

import { render, staticRenderFns } from "./AddCapacityAllocation.vue?vue&type=template&id=42a055fc&scoped=true&"
import script from "./AddCapacityAllocation.vue?vue&type=script&lang=ts&"
export * from "./AddCapacityAllocation.vue?vue&type=script&lang=ts&"
import style0 from "./AddCapacityAllocation.vue?vue&type=style&index=0&id=42a055fc&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42a055fc",
  null
  
)

export default component.exports

























import { Component, Prop, Vue } from "vue-property-decorator";
import { Map } from "@/common/MapB";
@Component({})
export default class HelloWorld extends Vue {
    private map!: Map;
    private place: string = "";

    private mounted(): void {
        this.map = new Map("map");
        this.map.initMap();

        this.map.clickGetPosition("click");
    }

    private getLngLat(): void {
        this.map.getPosition(this.place);
    }

    private submit(): void {
        if (this.map.lng && this.map.lat) {
            this.$emit(
                "lngLat",
                `${this.map.lng},${this.map.lat}`,
                this.map.address
            );
        } else {
            this.$message.error("请选择经纬度");
        }
    }
}
